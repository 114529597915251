import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Div from '../components/Div/Div.styles'
import ImgCoreLogin from '../images/registro-core.svg'

import MuiField from './../components/MuiField'
import Button from './../components/Button/Button'
import { Container } from '../components/styles/Layout.styles'
import { 
  styledRegisterCore,
  header
} from '../components/styles/RegisterCore.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledRegisterCore}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <Div
              maxWidth={['100%', 140]}
              alignItems={['center']}
              style={{
                margin: '0 auto',
                marginBottom: 30
              }}
            >
              <img
                src={ImgCoreLogin}
              />
            </Div>
            <H4>Registro Core</H4>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <span
              style={{
                display: 'block',
                marginBottom: 16
              }}
            >
              dados pessoais
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MuiField
                variant="outlined"
                label="Primeiro Nome"
                placeholder='Primeiro Nome'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Último Nome"
                placeholder='Último Nome'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Email"
                placeholder='Email'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Telefone"
                placeholder='Telefone'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Empresa"
                placeholder='Empresa'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
            </div>
            <span
              style={{
                display: 'block',
                marginBottom: 16,
                marginTop: 26
              }}
            >
              dados de localização
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MuiField
                variant="outlined"
                label="Endereço"
                placeholder='Endereço'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Cidade"
                placeholder='Cidade'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Estado"
                placeholder='Estado'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="País"
                placeholder='País'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
            </div>
            <span
              style={{
                display: 'block',
                marginTop: 26,
                marginBottom: 16
              }}
            >
              senha
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MuiField
                type='password'
                variant="outlined"
                label="Senha"
                placeholder='Senha'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                type='password'
                variant="outlined"
                label="Confirme sua senha"
                placeholder='Confirme sua senha'
                style={{
                  maxWidth: 328,
                  marginBottom: 30
                }}
              />
              <Button
                schema='secondary'
                color='white'
                variant='highEmphasis'
                width={['100%']}
                maxWidth={['150px']}
                style={{
                  display: 'flex',
                  border: 'none',
                  margin: '0 auto',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                }}
              >
                enviar
              </Button>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Registro Core' />
      <PageTemplate
        title='Registro Core'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
